import React from "react";
import Helmet from 'react-helmet';

import { Link } from 'gatsby';

/**
 * Components
 */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image"
import SEO from '../components/seo'

import Logo from '../components/header/assets/img/logo.svg';

const Maintenance = () => {
  return (
  <>
    <SEO title="Maintenance Page" description="" />
    <section className="layout-error maintenance d-flex align-items-center maintenance-content">
        <Container>
            <Row className="d-flex justify-content-center content">
                <Col lg={6} className="text-center content-div">
                        <Link to="/" className="logo-maintenance">
                          <Logo/>
                        </Link>

                        <h2 className="mb-4 custom-heading-line fox-font40">Please bear with us!</h2>
                        <p className="mb-4 custom-content-line fox-font19">We're doing some essential maintenance that requires a bit of downtime.</p>
                        <p className="mb-4 custom-content-line fox-font19">We hope to re-open the site for business shortly.</p>
                        <p className="custom-content-line fox-font19">Thank you for your patience and understanding.</p>
                </Col>
            </Row>
        </Container>
    </section>
    </>
  );
}

export default Maintenance;